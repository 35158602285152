import { FC, memo } from "react";

import dayjs from "dayjs";
import Link from "next/link";
import Analytics from "utils/Analytics";
import Utils from "utils/Utils";
import { BlogCardProps } from "./BlogUtils";

const BlogCard: FC<BlogCardProps> = ({ blog, className }) => {
  return (
    <Link href={`/blog/${Utils.slugString(blog.title)}/${blog.id}`}>
      <a
        onClick={() =>
          Analytics.track("blog_card", { title: blog?.title, id: blog?.id })
        }
        className={`d-block ${className}`}
      >
        <div className="card h-100 blogCard text-center">
          <picture>
            <source
              className={"img-fluid packageImg"}
              data-srcset={Utils.constructImageUrl(
                "300x200",
                "webp",
                blog.image.replace("bookweekendtours", "bwtexperiences")
              )}
              type="image/webp"
            />
            <source
              className={"img-fluid packageImg"}
              data-srcset={Utils.constructImageUrl(
                "300x200",
                "jpeg",
                blog.image.replace("bookweekendtours", "bwtexperiences")
              )}
              type="image/jpeg"
            />
            <img
              loading="lazy"
              src={Utils.constructImageUrl(
                "300x200",
                "webp",
                blog.image.replace("bookweekendtours", "bwtexperiences")
              )}
              alt={blog.title}
              className="img-fluid card-img-top"
            />
          </picture>
          <div className="card-body">
            <h1 className="lh-26 fs-18 primaryText fw-600">{blog.title}</h1>
            <p className={"card-text text-dark fs-14 fw-500"}>
              {Utils.truncateString(blog.description, 80, "...")}
            </p>
            <p className="card-text">
              <small className="text-muted fs-11">
                Published on {dayjs.unix(blog.created).format("DD MMM YYYY ")}
              </small>
            </p>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default memo(BlogCard);
