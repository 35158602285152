import { CSSProperties, FC } from "react";

interface CardPrimaryProps {
  style?: CSSProperties;
  className?: string;
  bodyClassName?: string;
}

const CardPrimary: FC<CardPrimaryProps> = ({
  style,
  className = "",
  bodyClassName = "",
  children,
}) => (
  <div style={style} className={`card ${className}`}>
    <div className={`card-body ${bodyClassName}`}>{children}</div>
  </div>
);

export default CardPrimary;
