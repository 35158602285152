import { FC, memo } from "react";

import Utils from "utils/Utils";
import BlogBanner from "./BlogBanner";
import BlogCard from "./BlogCard";
import { BlogListingType } from "./BlogUtils";

interface BlogProps {
  blogs: BlogListingType;
}

const Blog: FC<BlogProps> = ({ blogs }) => {
  if (!Utils.arrayCheck(blogs).length) return null;
  let firstBlog = blogs[0];

  return (
    <div className="container my-5">
      <h1 className="header mb-4">Our Blog</h1>
      <BlogBanner blog={firstBlog} className="mb-5" />
      <div className="row g-2">
        {blogs.slice(1).map((blog) => (
          <div key={blog.id} className="col-md-4 col-6 mb-4">
            <BlogCard blog={blog} className="h-100" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(Blog);
