import TinyLoader from "common/loaders/TinyLoader";
import Blog from "components/blog/Blog";
import BlogMediaCard from "components/blog/BlogMediaCard";
import { BlogCardDataType } from "components/blog/BlogUtils";
import { useEffect } from "react";
import { useGet } from "services/api/useFetch";
import Utils from "utils/Utils";

const BlogRowHolder = ({ forImportantUpdates = false }) => {
  const [blogs, { loading, error }, getBlog] = useGet(
    `v1/blogs/`,
    { data: [] },
    { initialLoading: true }
  );

  useEffect(() => {
    getBlog({
      queryParams: {
        page: 1,
        limit: Utils.isMobileView() ? 3 : 4,
        use_pagination_v2: true,
      },
    });
  }, []);

  if (loading) return <TinyLoader />;
  if (error) return null;
  if (forImportantUpdates)
    return (
      <div className="row">
        {blogs.data.map((blog: BlogCardDataType) => (
          <div className="col-12 mb-2">
            <BlogMediaCard blog={blog} />
          </div>
        ))}
      </div>
    );
  return (
    <div>
      <Blog blogs={blogs.data} />
    </div>
  );
};

export default BlogRowHolder;
