import querystring from "querystring";
import Link from 'next/link';
import Analytics from 'utils/Analytics';
import { Rate } from 'common/Icons/Icons';

interface RateExperienceProps {
    id: string,
    title: string
}

const RateExperienceCTA = ({id, title}: RateExperienceProps):JSX.Element => (
    <Link
        href={`/post-review/${id}?${querystring.stringify({title})}`}>
        <a 
        className={'btn btn-primary callToActionNormal d-flex align-items-center'}
        onClick={() => Analytics.track('Rate experience CTA', {id, title})}>
        <Rate height={16} width={16} fill={'#FFF'} /> 

        <span className="ps-1">Give your Feedback</span>
        </a>
    </Link>
)

export default RateExperienceCTA;
