import { ChevronRight } from "common/Icons/Icons";
import Link from "next/link";
import Analytics from "utils/Analytics";
import Utils from "utils/Utils";
import { BlogCardDataType } from "./BlogUtils";

const BlogMediaCard = ({ blog }: { blog: BlogCardDataType }) => {
  const image = blog.image.replaceAll("bookweekendtours", "bwtexperiences");

  return (
    <Link href={`/blog/${Utils.slugString(blog.title)}/${blog.id}`}>
      <a
        target="_blank"
        onClick={() =>
          Analytics.track("blog_media_card", {
            title: blog?.title,
            id: blog?.id,
          })
        }
        className={`d-block `}
      >
        <div className="mb-2">
          <div className="row g-4 align-items-center">
            <div className="col-4">
              <img
                loading="lazy"
                src={image}
                alt={blog.title}
                className="img-fluid card-img-top br-4 img-cover"
              />
            </div>
            <div className="col-8">
              <p className="fs-12 primaryText mb-1">{blog.title}</p>
              <div className="btn btn-outline-primary br-4 fs-11 w-50 p-1 text-center d-flex align-items-center justify-content-center">
                Read more
                <ChevronRight height={14} width={14} fill="#00ba8c" />
              </div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};
export default BlogMediaCard;
