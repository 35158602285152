import {
  AccommodationPricingType,
  SubscriptionType,
} from "components/ExperienceDetails/types";

import { IUser } from "services/AuthService/types";

export enum BookingStatus {
  SUCCESS = "SUCCESS",
  CANCELLED = "CANCELLED",
  INITIATED = "INITIATED",
  PROCESSING = "PROCESSING",
  FAILURE = "FAILURE",
}
enum Currency {
  USD = "USD",
  INR = "INR",
}
export interface IBookings {
  accommodation_details: AccommodationPricingType;
  amount: number;
  booking_id: string;
  can_book_again: boolean;
  basket_id: number;
  can_calendar: boolean;
  updated: number;
  time_remaining: {
    duration: number;
    unit: string;
  };
  basket_details: any;
  joining_details: string;
  pickup: string;
  can_rate_booking: boolean;
  created: number;
  currency: Currency;
  display_booking_id: string;
  end_date: number;
  end_time: number;
  experience_time_slot_id: number;
  id: number;
  is_active: boolean;
  is_booking_rated: boolean;
  is_calendared: boolean;
  is_deleted: boolean;
  is_experience_rated: boolean;
  mode: string;
  package: {
    id: 110;
    instructor_name: string;
    is_completed: boolean;
    message: string;
    name: string;
    pickup: string;
    time_slot: string;
    url: string;
    things_to_carry: string;
  };
  pax: number;
  payable_amount: number;
  payment_link: string;
  promo_code: string;
  rating: number;
  referee_booking_id: number;
  remaining_amount: number;
  start_date: number;
  start_time: number;
  status: BookingStatus;
  subscription_details: SubscriptionType;
  user: IUser;
}

// Basket details
export interface BasketOrderDetailsRoot {
  id: number;
  created: number;
  updated: number;
  is_active: boolean;
  is_deleted: boolean;
  display_booking_id: string;
  booking_id: string;
  pax: number;
  currency: string;
  amount: number;
  payable_amount: number;
  mode: string;
  status: string;
  start_date: number;
  end_date: number;
  start_time: any;
  end_time: any;
  contact_name: any;
  contact_mobile: any;
  contact_country_code: any;
  promo_code: any;
  offer_code: any;
  notifications: Notifications;
  extras: Extras;
  searchable_text: any;
  referee_booking_id: any;
  experience_time_slot_id: any;
  basket_id: number;
  order_subscription_plan_id: number;
  can_rate_booking: boolean;
  is_booking_rated: boolean;
  is_experience_rated: boolean;
  rating: any;
  remaining_amount: number;
  payment_link: any;
  can_book_again: boolean;
  is_calendared: boolean;
  can_calendar: any;
  package: any;
  payment_info: PaymentInfo;
  accommodation_details: any;
  user: User;
  basket_details: BasketDetails;
}

export interface Notifications {}

export interface Extras {}

export interface PaymentInfo {
  id: string;
  entity: string;
  plan_id: string;
  status: string;
  current_start: any;
  current_end: any;
  ended_at: any;
  quantity: number;
  notes: Notes;
  charge_at: number;
  start_at: number;
  end_at: number;
  auth_attempts: number;
  total_count: number;
  paid_count: number;
  customer_notify: boolean;
  created_at: number;
  expire_by: number;
  short_url: string;
  has_scheduled_changes: boolean;
  change_scheduled_at: any;
  source: string;
  remaining_count: number;
}

export interface Notes {
  booking_id: string;
}

export interface User {
  id: number;
  name: string;
  email: string;
  mobile: string;
}

export interface BasketDetails {
  id: number;
  title: string;
  short_title: string;
  price: number;
  keywords: string;
  description: string;
  body: Body;
  tags: any[];
  images: Image[];
  blogs: any[];
  experiences: Experience[];
  offerings: Offering[];
}

export interface Body {
  time: number;
  blocks: Block[];
  version: string;
}

export interface Block {
  id: string;
  data: Data;
  type: string;
}

export interface Data {
  text: string;
  level?: number;
}

export interface Image {
  id: number;
  created: number;
  updated: number;
  is_active: boolean;
  is_deleted: boolean;
  name: string;
  urls: Urls;
}

export interface Urls {
  jpeg: string;
  webp: string;
  original: string;
}

export interface Experience {
  id: number;
  created: number;
  updated: number;
  is_active: boolean;
  title: string;
  slug: string;
  short_title: string;
  about_location: any;
  location_covered: any;
  vendor: Vendor;
  trek_level: any;
  start_time: string;
  end_time: string;
  trek_included: boolean;
  pickup: any;
  duration: number;
  durations: any[];
  duration_type: string;
  season: string;
  seo_description: string;
  keywords: string;
  short_itinerary: string;
  accommodation: any;
  things_to_carry: string;
  is_available: boolean;
  availability_count: any;
  badge: any;
  price: number;
  discount_flat: any;
  discount_percent: any;
  best_selling: boolean;
  long_weekend_tour: boolean;
  is_liked: boolean;
  offer_details: OfferDetails;
  categories: Category[];
  rating: number;
  total_ratings: number;
  batch_size: number;
  time_slot: string;
  url: string;
  instructor_name: any;
  thumbnail_image: ThumbnailImage;
  images: Image2[];
  accommodation_pricing: AccommodationPricing[];
  subscription_plans: SubscriptionPlan[];
  upcoming_trips: UpcomingTrip[];
  locations: Location[];
}

export interface Vendor {
  id: number;
  name: string;
  logo: string;
  rating: number;
  about: any;
  advance_pay_percent: number;
}

export interface OfferDetails {
  end: string;
}

export interface Category {
  id: number;
  name: string;
  slug: string;
  path?: string;
  is_parent: boolean;
}

export interface ThumbnailImage {
  id: number;
  created: number;
  updated: number;
  is_active: boolean;
  is_deleted: boolean;
  name: string;
  urls: Urls2;
}

export interface Urls2 {
  jpeg: string;
  webp: string;
  original: string;
}

export interface Image2 {
  id: number;
  created: number;
  updated: number;
  is_active: boolean;
  is_deleted: boolean;
  name: string;
  urls: Urls3;
}

export interface Urls3 {
  jpeg: string;
  webp: string;
  original: string;
}

export interface AccommodationPricing {
  is_private: boolean;
  max_guests: any;
  individual_pricing: any;
  id: number;
  created: number;
  updated: number;
  is_active: boolean;
  is_deleted: boolean;
  accommodation_type: string;
  price: number;
  pax: any;
  package_id: number;
}

export interface SubscriptionPlan {
  id: number;
  name: string;
  duration: number;
  discount_percent: number;
  discount_price: number;
  best_deal: boolean;
}

export interface UpcomingTrip {
  id: number;
  created: number;
  updated: number;
  is_active: boolean;
  is_deleted: boolean;
  start: number;
  end: number;
  package_id: number;
  time_slots: any[];
}

export interface Location {
  id: number;
  name: string;
  slug: string;
}

export interface Offering {
  id: number;
  title: string;
  description: any;
  short_title: string;
  price: number;
  url: string;
  tags: any[];
  images: Image3[];
}

export interface Image3 {
  id: number;
  created: number;
  updated: number;
  is_active: boolean;
  is_deleted: boolean;
  name: string;
  urls: Urls4;
}

export interface Urls4 {
  jpeg: string;
  webp: string;
  original: string;
}
