import BlogRowHolder from "common/blog/BlogRowHolder";
import CardPrimary from "common/Cards/CardPrimary";
import CommunityCard from "./CommunityCard";
import ReferralCard from "../referralCard/ReferralCard";
import TinyLoader from "common/loaders/TinyLoader";
import { useAuth } from "services/AuthService/AuthProvider";

const ImportantBookingUpdates = () => {
  const { userData, userDataLoading } = useAuth();
  return (
    <div>
      {userDataLoading ? (
        <TinyLoader />
      ) : (
        <ReferralCard enableToggle userData={userData} />
      )}
      <CardPrimary className="bg-light mb-2">
        <p className="fw-500">Checkout out our blog</p>
        <BlogRowHolder forImportantUpdates />
      </CardPrimary>
      <CommunityCard />
    </div>
  );
};
export default ImportantBookingUpdates;
