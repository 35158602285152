import { FC, useState } from "react";

import Analytics from "utils/Analytics";
import Button from "common/buttons/Button";
import CardPrimary from "common/Cards/CardPrimary";
import HowReferralWorkModal from "common/referrals/HowReferralWorkModal";
import { IUser } from "services/AuthService/types";
import Label from "common/text/Label";
import Link from "next/link";
import { SITE_URL } from "constants/Constants";
import ShareDrawer from "common/drawer/ShareDrawer";
import { ShareNew } from "common/Icons/Icons";
import styles from "../Profile.module.scss";
import { useToggle } from "utils/customHooks";

const ReferralCard: FC<{
  userData: IUser;
  enableToggle?: boolean;
}> = ({ userData, enableToggle }) => {
  const [showShareDrawer, setShowShareDrawer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useToggle(!enableToggle);
  const isReferralExist = userData.referral_code;
  const shareText = `Use my referral code and get a chance to win one month yoga subscription. Referral Code: ${userData?.referral_code}`;
  const shareUrl = `${SITE_URL}/referral?referral_code=${userData?.referral_code}`;

  return (
    <>
      {enableToggle && (
        <div
          onClick={() => {
            setShow();
          }}
          className="fs-14 mt-3 mb-2"
        >
          <img
            src="https://cdn.bwtexperiences.com/image-bank/free_yoga_month_new.webp"
            alt="Free Yoga"
            className="img-fluid img-thumbnail"
          />
        </div>
      )}
      <ShareDrawer
        text={shareText}
        url={shareUrl}
        show={showShareDrawer}
        onHide={() => setShowShareDrawer(false)}
      />
      <HowReferralWorkModal
        show={showModal}
        onHide={() => {
          Analytics.track("how does it work modal closed");
          setShowModal(false);
        }}
      />
      {show && (
        <CardPrimary className="shadow-sm mb-3">
          {isReferralExist && (
            <div
              onClick={() => {
                Analytics.track("Referral share icon");
                setShowShareDrawer(true);
              }}
              className={`mb-3 pointer d-flex justify-content-between align-items-center mb-0 p-2 ${styles.referralBox}`}
            >
              <Label className="mb-1 fs-14 fw-500" text={"Referral Code"} />

              <p className="mb-0 fs-16 fw-500">{userData?.referral_code}</p>
              <ShareNew color={"#2c3e50"} size={20} />
            </div>
          )}

          <div className="bg-warning text-dark p-2 rounded">
            <p className={"mb-0 fw-500 fs-12"}>
              Refer and get one month of yoga experience absolutely free.*
            </p>
          </div>
          <div className="my-2" />

          {!isReferralExist && (
            <Link href="/discover/online-experiences/yoga">
              <a
                className="btn btn-outline-primary w-100"
                onClick={() =>
                  Analytics.track("Terms and Services clicked for referral")
                }
              >
                Book your first session
              </a>
            </Link>
          )}
          <div className="my-2" />
          <Button
            type="button"
            onClick={() => {
              Analytics.track("How does it work cta");
              setShowModal(true);
            }}
            className="btn-primary w-100 text-white"
          >
            How does it work?
          </Button>
          <Link href="/terms-and-more">
            <a
              className="fs-14 fw-500"
              onClick={() =>
                Analytics.track("Terms and Services clicked for referral")
              }
            >
              *Terms and Services
            </a>
          </Link>
        </CardPrimary>
      )}
    </>
  );
};

export default ReferralCard;
