import { FC, memo } from "react";

import dayjs from "dayjs";
import Link from "next/link";
import Utils from "utils/Utils";
import { BlogCardProps } from "./BlogUtils";

const BlogBanner: FC<BlogCardProps> = ({ blog, className = "" }) => {
  return (
    <Link href={`/blog/${Utils.slugString(blog.title)}/${blog.id}`}>
      <a className={`d-block ${className}`}>
        <div className="card">
          <div className="row g-0 align-items-center">
            <div className="col-md-6 col-lg-6 col-12 order-1 order-md-2">
              <img
                src={blog.image.replace("bookweekendtours", "bwtexperiences")}
                className="card-img img-fluid blogImg"
                alt={blog.title}
              />
            </div>
            <div className="col-md-6 col-lg-6 col-12 order-2 order-md-1 text-center">
              <div className="card-body">
                <h1 className="lh-32 fs-24 primaryText fw-600">{blog.title}</h1>
                <p className={"card-text text-dark fs-14 fw-500"}>
                  {Utils.truncateString(blog.description, 80, "...")}
                </p>

                <p className="card-text">
                  <small className="text-muted fs-11">
                    Published on{" "}
                    {dayjs.unix(blog.created).format("DD MMM YYYY")}
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default memo(BlogBanner);
