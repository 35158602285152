import { FC, MouseEventHandler, ReactNode, memo } from "react";

import TinyLoader from "../loaders/TinyLoader";

interface ButtonProps {
  children?: ReactNode;
  text?: string;
  id?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  className?: string;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
  style?: React.CSSProperties;
}

const Button: FC<ButtonProps> = (props) => (
  <button
    type={props.type}
    disabled={props.disabled}
    id={props.id}
    onClick={props.onClick}
    className={`btn ${props.className}`}
    style={props.style}
  >
    {props.loading ? (
      <div className="d-flex justify-content-center">
        <TinyLoader />
      </div>
    ) : (
      props.children || props.text
    )}
  </button>
);

export default memo(Button);
