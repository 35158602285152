import { FC, ReactNode, memo } from "react";

import styles from "./Dropdown.module.scss";

interface DropdownProps {
  overlay?: ReactNode;
  children?: ReactNode;
  leftAlign?: boolean;
}

const Dropdown: FC<DropdownProps> = ({ children, overlay, leftAlign }) => {
  return (
    <div className={styles.dropdown}>
      {children}
      <div className={`${styles.overlay} ${leftAlign ? styles.leftAlign : ""}`}>
        {overlay}
      </div>
    </div>
  );
};

export default memo(Dropdown);
