import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { FC, memo } from "react";

import Analytics from "utils/Analytics";
import { Copy } from "common/Icons/Icons";
import Utils from "utils/Utils";
import dynamic from "next/dynamic";

const Drawer = dynamic(() => import("./Drawer"), { ssr: false });

interface ShareDrawerProps {
  show: boolean;
  onHide: () => void;
  text: string;
  iconSize?: number;
  url?: string;
}

const socialArr = (props: {
  text: string;
  url?: string;
  iconSize?: number;
}) => [
  {
    text: "WhatsApp",
    child: (
      <WhatsappShareButton
        title={props.text || "A trip by BWT"}
        url={props.url || window.location.href}
        separator={" "}
      >
        <WhatsappIcon size={props.iconSize || 32} round={true} />
      </WhatsappShareButton>
    ),
  },
  {
    text: "Facebook",
    child: (
      <FacebookShareButton
        title={props.text || "A trip by BWT"}
        url={props.url || window.location.href}
      >
        <FacebookIcon size={props.iconSize || 32} round={true} />
      </FacebookShareButton>
    ),
  },
  {
    text: "Twitter",
    child: (
      <TwitterShareButton
        title={props.text || "A trip by BWT"}
        url={props.url || window.location.href}
      >
        <TwitterIcon size={props.iconSize || 32} round={true} />
      </TwitterShareButton>
    ),
  },
  {
    text: "Email",
    child: (
      <EmailShareButton
        subject={props.text}
        body={props.text || "A trip by BWT"}
        url={props.url || window.location.href}
      >
        <EmailIcon size={props.iconSize || 32} round={true} />
      </EmailShareButton>
    ),
  },
  {
    text: "Copy Link",
    child: (
      <div
        className={"iconBgCircle bg-dark"}
        onClick={() =>
          Utils.copyText(`${props.text} - ${props.url || window.location.href}`)
        }
      >
        <Copy color={"#FFF"} height={15} />
      </div>
    ),
  },
];

const ShareDrawer: FC<ShareDrawerProps> = ({
  show,
  onHide,
  text,
  url,
  iconSize,
}) => {
  if (!process.browser) return null;

  const isDesktop = window.innerWidth > 768;

  return (
    <Drawer
      header="Share"
      show={show}
      onHide={onHide}
      size={isDesktop ? "30%" : "60%"}
      placement={isDesktop ? "right" : "bottom"}
    >
      {socialArr({ text, url, iconSize }).map((it) => (
        <div key={it.text} className="mb-4">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <span>{it.text}</span>

            <div
              className="pointer"
              onClick={() =>
                Analytics.track(`${Utils.slugString(it.text)}_social`)
              }
            >
              {it.child}
            </div>
          </div>
        </div>
      ))}
    </Drawer>
  );
};

export default memo(ShareDrawer);
