import { MailIcon } from "@heroicons/react/outline";
import { WhatsApp } from "common/Icons/Icons";
import Button from "common/buttons/Button";
import querystring from "querystring";
import Analytics from "utils/Analytics";
const Diet_Plan_Link =
  "https://cdn-bwtexperiences.s3.ap-south-1.amazonaws.com/BWT+Weight+Loss+Diet+Plan+%231.pdf";
const PHONE = "919354062108";
const MESSAGE =
  "Hey Team! I would like to know more about the customized diet plans. Please get in touch with me.";
const WhatsappLink = () => (
  <Button className="callToActionOutline btn-sm p-2">
    <a
      href={`https://api.whatsapp.com/send?${querystring.stringify({
        phone: PHONE,
        text: MESSAGE,
      })}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <p className="fw-500 mb-0 fs-12 text-dark d-flex align-items-center">
        <WhatsApp height={16} />
        WhatsApp
      </p>
    </a>
  </Button>
);
const EmailLink = () => (
  <Button className="callToActionOutline btn-sm p-2">
    <a
      href="mailto:hello@bwtexperiences.com"
      rel="noopener noreferrer"
      target="_blank"
    >
      <p className="fw-500 mb-0 fs-12 text-dark d-flex gap-1 align-items-center">
        <MailIcon height={16} />
        Email
      </p>
    </a>
  </Button>
);
const DietPlanCard = () => (
  <div
    className="card my-2 bg-gradient"
    onClick={(e) => {
      e.stopPropagation();
      Analytics.track("Diet Plan CTA", {});
    }}
  >
    <div className="card-body d-flex align-items-center justify-content-between">
      <p className="fw-600 text-white mb-0">
        Download your free basic diet plan 🥗
      </p>
      <a
        onClick={(e) => {
          e.stopPropagation();
          Analytics.track("Diet Plan CTA", {});
        }}
        className="btn callToAction"
        target="_blank"
        type="download"
        href={`${Diet_Plan_Link}`}
        rel="noopener noreferrer"
      >
        Download Diet Plan
      </a>
    </div>
    <div
      className="p-3 br-4 bg-white m-2 gap-2 d-flex align-items-start justify-content-between"
      onClick={(e) => {
        e.stopPropagation();
        Analytics.track("Diet Plan CTA", {});
      }}
    >
      <p className="fw-400 fs-14 w-75 text-dark mb-0">
        If you are looking for customized diet plans, please reach out to us at
        via email or whatsapp. We will get back to you within 24 hours.
      </p>
      <div className="d-flex align-items-center justify-content-start gap-2">
        <WhatsappLink />
        <EmailLink />.
      </div>
    </div>
  </div>
);

export default DietPlanCard;
