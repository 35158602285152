import React, { FC } from "react";

import Analytics from "utils/Analytics";
import { Bell } from "common/Icons/Icons";
import GoogleLogin from "react-google-login";
import Utils from "utils/Utils";
import { usePut } from "services/api/useFetch";
import { useRouter } from "next/router";

const GoogleCalendarIntegration: FC<{ booking_id: string }> = ({
  booking_id,
}) => {
  const router = useRouter();

  const [, , addCalendarInvite] = usePut(
    `v1/orders/${booking_id}/calendar/`,
    null,
    {
      onSuccess: () => {
        Utils.successMessage("Event added to calendar");
        Analytics.track("Event added to calendar", { booking_id });
        router.push("/profile/my-bookings");
      },
      onError: () => {
        Utils.errorMessage("Something went wrong!");
      },
    }
  );

  const onFailure = () => Utils.errorMessage("Something went wrong");

  return (
    <GoogleLogin
      className="pointer"
      render={(renderProps) => (
        <AddReminderButton {...renderProps} booking_id={booking_id} />
      )}
      scope={"https://www.googleapis.com/auth/calendar.events"}
      onSuccess={(response) => {
        const { tokenId, accessToken } = response as {
          tokenId: string;
          accessToken: string;
        };
        if (tokenId && accessToken) {
          addCalendarInvite({
            payload: {
              token: tokenId,
              access_token: accessToken,
              token_type: "email",
            },
          });
        } else {
          onFailure();
        }
      }}
      onFailure={onFailure}
      clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
    />
  );
};

const AddReminderButton: FC<{
  onClick: () => void;
  disabled?: boolean;
  booking_id: string;
}> = ({ onClick, disabled, booking_id }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className="btn btn-blue callToActionNormal"
  >
    <div
      className={"d-flex align-items-center"}
      onClick={() => Analytics.track("Add Reminder CTA", { booking_id })}
    >
      <Bell height={18} width={18} fill={"#FFF"} />
      <div className="px-1" />
      <span>Add a Reminder</span>
    </div>
  </button>
);

export default GoogleCalendarIntegration;
