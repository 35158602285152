export const getStatus = (status: string) => {
  switch (`${status}`.toUpperCase()) {
    case "SUCCESS":
    default:
      return (
        <span className={"d-flex align-items-center text-success"}>
          Successful
        </span>
      );
    case "FAILURE":
      return (
        <span className={"d-flex align-items-center text-danger"}>Failure</span>
      );
    case "PROCESSING":
      return (
        <span className={"d-flex align-items-center text-info"}>
          In process
        </span>
      );
    case "CANCELLED":
      return (
        <span className={"d-flex align-items-center text-danger"}>
          Cancelled
        </span>
      );
  }
};
