import CardPrimary from "common/Cards/CardPrimary";

const CommunityCard = () => {
  return (
    <CardPrimary className="bg-light mb-2">
      <p className="fw-500">Checkout out our Community</p>
      <p>
        Join our community to get latest updates on experiences around the
        world.
      </p>
      <a
        href="https://club.bwtexperiences.com/"
        className="btn btn-primary text-white btn-block"
        target="blank"
      >
        Join BWT Club
      </a>{" "}
    </CardPrimary>
  );
};
export default CommunityCard;
