import { EmptyClose } from "common/Icons/Icons";
import { FC } from "react";
import { Modal } from "react-bootstrap";

const ITEMS = [
  "Spread the word to your loved ones about your experience of practicing yoga with us.",
  "Pass on the referral code to them.",
  "Get your loved ones excited to commence their yoga journey with us.",
  "Ask them to use the shared referral code at the checkout section of their preferred yoga experiences.",
  "Grab a 100% Free Online Yoga Subscription for a month from us on each referral!",
];

const HowReferralWorkModal: FC<{ show: boolean; onHide: () => void }> = ({
  show,
  onHide,
}) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
    >
      <div className="p-md-5 p-3">
        <div className="mb-3 pb-4 border-bottom d-flex justify-content-between align-items-center">
          <h3 className="mb-0">How does it work?</h3>
          <div onClick={onHide} className="pointer">
            <EmptyClose size={32} />
          </div>
        </div>
        <ul>
          {ITEMS.map((item) => (
            <li key={item} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
        <p className="mb-0 p-3 bg-blue rounded">
          We are super excited to welcome your loved ones and look forward to
          rewarding you with a 100% free yoga subscription for your awesome
          efforts.
        </p>
      </div>
    </Modal>
  );
};

export default HowReferralWorkModal;
